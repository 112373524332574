import React, { useEffect } from 'react';
import T from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import get from 'lodash.get';
import { Masonry } from 'masonic';
import {
  themeVal,
  glsp,
  media,
  visuallyHidden
} from '@devseed-ui/theme-provider';
import {
  CollecticonBrandGithub,
  CollecticonBrandLinkedin,
  CollecticonBrandTwitter,
  CollecticonLink
} from '@devseed-ui/collecticons';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageSuptitle,
  InpageBody,
  InpageMetaDetails
} from '../../styles/inpage';
import UniversalGridder from '../../styles/universal-gridder';
import Prose from '../../styles/typography/prose';
import { stripe } from '../../styles/motifs';
import { SupHeading } from '../../styles/typography/supheading';
import {
  SectionBody,
  SectionCardGroup,
  sectionCardGroupHeaderGrid,
  SectionHeader,
  SectionHeadline,
  SectionTitle
} from '../../styles/section';
import { CardBlog } from '../../components/cards';
import Media from '../../components/media';
import InpageNavigation from '../../components/inpage-nav';

import useMediaAware from '../../utils/media-query-aware-hook';
import { prepareAuthorProperties } from '../../utils/utils';
import { MasonryItemsListElement } from '../../styles/masonry';

const MemberContent = styled(UniversalGridder)`
  grid-template-rows: auto 1fr;

  &::after {
    ${stripe};
    grid-row: 1 / span 2;
    top: ${glsp(-2)};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-column-start: content-3;
    grid-column-end: full-end;

    ${media.smallUp`
      grid-column-start: content-3;
      top: ${glsp(-2)};
    `}

    ${media.mediumUp`
      grid-column-start: content-6;
      top: ${glsp(-4)};
    `}

    ${media.largeUp`
      grid-column-start: content-10;
      top: ${glsp(-14)};
    `}
  }
`;

const MemberProse = styled(Prose)`
  grid-row: 2;
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-2 / span ;
  `}

  ${media.largeUp`
    grid-row: 1 / span 2;
    grid-column-end: span 7;
  `}

  ${media.xlargeUp`
    grid-column-end: span 6;
  `}
`;

const MemberMedia = styled(Media)`
  grid-row: 1;
  grid-column: full-start / full-end;

  ${media.smallUp`
    grid-column: full-start / content-end;
  `}

  ${media.mediumUp`
    grid-column: full-start / content-7;
  `}

  ${media.largeUp`
    grid-column: 9 / full-end;
    margin-top: ${glsp(-8)};
  `}

  ${media.xlargeUp`
    grid-column: 8 / span 6;
  `}

  > div {
    display: block;
  }
`;

const MemberContacts = styled.section`
  grid-row: 3;
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-row: 3;
  `}

  ${media.largeUp`
    grid-row: 2;
    grid-column: 9 / span 4;
  `}

  ${media.xlargeUp`
    grid-column: 8 / span 6;
  `}
`;

const MemberContactsTitle = styled.h1`
  ${visuallyHidden};
`;

const ContactList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  white-space: nowrap;

  > * {
    margin-bottom: ${glsp()};
  }

  > *:not(:last-child) {
    margin-right: ${glsp(2)};
  }

  ${media.mediumUp`
    > * {
      margin-bottom: ${glsp(2)};
    }

    > *:not(:last-child) {
      margin-right: ${glsp(4)};
    }
  `}
`;

const ContactListItem = styled.li`
  span {
    ${visuallyHidden};
  }

  em {
    grid-row: 2;
    grid-column: 2;
    grid-row: 2;
    color: ${themeVal('color.primary')};
    font-style: normal;
    font-variation-settings: 'wdth' 96, 'wght' 512;
    font-size: 1rem;
    line-height: 1;
  }
`;

const ContactListLink = styled.a`
  display: grid;
  grid-column-gap: ${glsp(1)};

  &,
  &:visited {
    color: inherit;
  }

  svg {
    grid-row: 1;
    align-self: center;
  }
`;

export const ContactListItemTitle = styled(SupHeading).attrs({
  as: 'h6',
  variation: 'base'
})`
  grid-row: 1;
  grid-column: 2;
`;

const TeamSingleTemplate = ({ data, location, pageContext }) => {
  const { previous, next } = pageContext;
  const teamMember = data.teamMember;
  const {
    title,
    teaser,
    expertise,
    location: memberLocation,
    pronouns,
    media,
    social
  } = teamMember.frontmatter;
  const { archived } = teamMember;

  const cardImage = get(media, 'card.url.childImageSharp.fluid');
  const website = get(social, 'website', '');

  const prevPost = previous
    ? {
        url: `/team/${previous.slug}`,
        linkTitle: 'Visit previous team member',
        title: previous.frontmatter.title
      }
    : null;
  const nextPost = next
    ? {
        url: `/team/${next.slug}`,
        linkTitle: 'Visit next team member',
        title: next.frontmatter.title
      }
    : null;

  return (
    <Layout
      location={location}
      title={title}
      description={teaser}
      metaImage={cardImage && cardImage.src}
    >
      <Inpage>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{title}</InpageTitle>
              {archived && (
                <p>
                  <em>{title} is a Development Seed alum.</em>
                </p>
              )}
              <InpageSuptitle>
                <Link to='/team' title='View Team page'>
                  Team
                </Link>
              </InpageSuptitle>
              <InpageMetaDetails>
                <dt>Expertize</dt>
                <dd>{expertise}</dd>
                <dt>Location</dt>
                <dd>{memberLocation}</dd>
                <dt>Pronouns</dt>
                <dd>{pronouns}</dd>
              </InpageMetaDetails>
            </InpageHeadline>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <MemberContent>
            {cardImage && (
              <MemberMedia
                size='default'
                decoration='none'
                fluid={cardImage}
                alt={`${title} picture`}
              />
            )}
            <MemberProse
              dangerouslySetInnerHTML={{ __html: teamMember.html }}
            />
            <MemberContacts>
              <MemberContactsTitle>Get in touch</MemberContactsTitle>
              <ContactList>
                {social.github && (
                  <ContactListItem>
                    <ContactListLink
                      href={`https://github.com/${social.github}`}
                      title='View GitHub profile'
                    >
                      <CollecticonBrandGithub />
                      <ContactListItemTitle>GitHub</ContactListItemTitle>
                      <span>: </span>
                      <em>{social.github}</em>
                    </ContactListLink>
                  </ContactListItem>
                )}
                {social.twitter && (
                  <ContactListItem>
                    <ContactListLink
                      href={`https://twitter.com/${social.twitter}`}
                      title='View Twitter profile'
                    >
                      <CollecticonBrandTwitter />
                      <ContactListItemTitle>Twitter</ContactListItemTitle>
                      <span>: </span>
                      <em>@{social.twitter}</em>
                    </ContactListLink>
                  </ContactListItem>
                )}
                {social.linkedin && (
                  <ContactListItem>
                    <ContactListLink
                      href={`https://www.linkedin.com/in/${social.linkedin}`}
                      title='View LinkedIn profile'
                    >
                      <CollecticonBrandLinkedin />
                      <ContactListItemTitle>LinkedIn</ContactListItemTitle>
                      <span>: </span>
                      <em>{social.linkedin}</em>
                    </ContactListLink>
                  </ContactListItem>
                )}
                {website && (
                  <ContactListItem>
                    <ContactListLink
                      href={website}
                      title='View personal website'
                    >
                      <CollecticonLink />
                      <ContactListItemTitle>Website</ContactListItemTitle>
                      <span>: </span>
                      <em>{website.replace(/https?:\/\//, '')}</em>
                    </ContactListLink>
                  </ContactListItem>
                )}
              </ContactList>
            </MemberContacts>
          </MemberContent>

          {!!data.latestBlog.nodes.length && (
            <SectionLatestBlogPosts
              posts={data.latestBlog.nodes}
              name={title}
            />
          )}

          {(prevPost || nextPost) && (
            <InpageNavigation prevPost={prevPost} nextPost={nextPost} />
          )}
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default TeamSingleTemplate;

TeamSingleTemplate.propTypes = {
  data: T.object,
  location: T.object,
  pageContext: T.shape({
    id: T.string,
    next: T.object,
    previous: T.object
  })
};

export const pageQuery = graphql`
  query TeamById($id: String!) {
    teamMember: team(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        teaser
        location
        pronouns
        expertise
        social {
          twitter
          github
          linkedin
          website
        }
        media {
          card {
            url {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
      archived
    }
    latestBlog: allBlog(
      filter: {
        frontmatter: { authors: { elemMatch: { id: { eq: $id } } } }
        published: { eq: true }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...BlogCardData
      }
    }
  }
`;

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// SectionLatest - Auxiliary components                                       //
//                                                                            //

const latestItemsRenderFn = ({ index, data }) => {
  const { excerpt, slug, date, timeToRead } = data;
  const { topics, title, media, authors, teaser } = data.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  const authorsFields = prepareAuthorProperties(authors);

  return (
    <CardBlog
      title={title}
      slug={slug}
      readTime={timeToRead}
      cardImage={cardImage}
      authors={authorsFields}
      topics={topics}
      date={date}
      excerpt={teaser || excerpt}
      index={index + 1}
    />
  );
};

const SectionLatestBlogPosts = (props) => {
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <SectionCardGroup>
      <SectionHeader grid={sectionCardGroupHeaderGrid}>
        <SectionHeadline>
          <SectionTitle>More from {props.name}</SectionTitle>
        </SectionHeadline>
      </SectionHeader>
      <SectionBody>
        <Masonry
          items={props.posts}
          overscanBy={Infinity}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.id}
          render={latestItemsRenderFn}
        />
      </SectionBody>
    </SectionCardGroup>
  );
};

SectionLatestBlogPosts.propTypes = {
  posts: T.array,
  name: T.string
};

//                                                                            //
// END   SectionLatest - Auxiliary components                                 //
// // // // // // // // // // // // // // // // // // // // // // // // // // //
